<template>
    <div class="content-section introduction">

      <div class="feature-intro">
        <h1>{{tLabel('Crea Soste Report') }}</h1>
      </div>

      <v-form ref="createLayoverHeaderForm">
        <v-container fluid>   
            <v-row>
              <v-col cols="8">
                <v-autocomplete :label="tLabel('Client')" v-model="layoverHeaderReportReq.filter.clientInfo" 
                  :items="owners"  item-text="code" return-object />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <DateTimeAdapter
                  :label="tLabelRequired('Date from')"
                  v-model.trim="startTime"
                  :rules="[rules.required]"
                  defaultTime="00:00:00"
                />
              </v-col>
              <v-col cols="6">
                <DateTimeAdapter
                  :label="tLabelRequired('Date to')"
                  v-model.trim="endTime"
                  :rules="[rules.required]"
                  defaultTime="23:59:59"
                />
              </v-col>
            </v-row>           
        </v-container>
      </v-form>

      <v-container>
        <v-row>        
          <v-col cols="12" style="text-align: right">
            <v-btn color="success" class="pa-2 ma-2" @click="save" medium>Salva</v-btn>    
            <v-btn color="error" class="pa-2 ma-2" @click="discard" medium>Annulla</v-btn>          
          </v-col >        
        </v-row>
      </v-container>

    </div>       
</template>

<script>
import i18n from "../../i18n";
import FormatsMixins from "../../mixins/FormatsMixins";
import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";
import moment from "moment"; 

export default {
  name: "CreateLayoverHeader", 
  title: i18n.t("Create Layover Header"),
  data() {
    return {
      layoverHeaderReportReq: {
        type : "LAYOVER",
        filter : {}
      },
      startTime: null,
      endTime: null,
      owners: [],
    }
  },
  async mounted() {
    this.owners= await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
  },
  methods: {   
    async save() {
      const valid = this.$refs.createLayoverHeaderForm.validate();
      if (!valid) {
        return;
      }
      
      this.layoverHeaderReportReq.filter.startTime = JSON.parse(JSON.stringify(moment(this.startTime).format("YYYY-MM-DDTHH:mm:ss")));
      this.layoverHeaderReportReq.filter.endTime = JSON.parse(JSON.stringify(moment(this.endTime).format("YYYY-MM-DDTHH:mm:ss")));
      console.log("save layoverHeader", this.layoverHeaderReportReq);
      await this.$api.reportData.report(this.layoverHeaderReportReq);
      this.$router.go(-1);
    },
    discard() {     
      console.log("discard layoverHeader", this.layoverHeaderReportReq);
      this.$router.go(-1);
    },
  },
  mixins: [FormatsMixins],
  components: {
    DateTimeAdapter
  }
}
</script>
